/* THEME */
/* Fonts, custom properties, custom selectors and custom media queries */
@import "https://assets.finn.no/pkg/troika-fonts/v1/import.css";
@import "@finn-no/troika-postcss-preset/theme.css";
/* GENERIC */
/* Low-specificity, far-reaching rulesets (e.g. resets). */
@import "normalize.css/opinionated.css";
@import "@finn-no/troika-css-core/src/box-model.css";

/* ELEMENTS */
/* Unclassed HTML elements (e.g. a {}, blockquote {}, address {}). */
@import "@finn-no/troika-css-core/src/elements.css";

/* OBJECTS */
/* Objects, abstractions, and design patterns. */
@import "@finn-no/troika-css-core/src/probably-touch.css";
@import "@finn-no/troika-css-core/src/spaden-reset.css";
