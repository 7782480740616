.spaden-reset {
    button,
    input[type="search"] {
        border: none;
        border-radius: 0;
        background: none;
        text-indent: 0;
        transition: none;
        box-shadow: none;
        display: inline-block;
        width: auto;
        padding: inherit;
        height: auto;
        background-color: #fff;
        outline: 0;
        line-height: inherit;
    }

    ol,
    h2 {
        margin: 0;
    }

    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
    }
}
