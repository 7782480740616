@custom-media --max480 (max-width: 479px);
@custom-media --min480 (min-width: 480px);
@custom-media --max640 (max-width: 639px);
@custom-media --min640 (min-width: 640px);
@custom-media --max768 (max-width: 767px);
@custom-media --min768 (min-width: 768px);
@custom-media --max900 (max-width: 899px);
@custom-media --min900 (min-width: 900px);
@custom-media --max990 (max-width: 989px);
@custom-media --min990 (min-width: 990px);
@custom-media --max1235 (max-width: 1234px);
@custom-media --min1235 (min-width: 1235px);
@custom-media --max1300 (max-width: 1299px);
@custom-media --min1300 (min-width: 1300px);

:root {
    --troika-spacing-0: 0px;
    --troika-spacing-25: 2px;
    --troika-spacing-50: 4px;
    --troika-spacing-100: 8px;
    --troika-spacing-150: 12px;
    --troika-spacing-200: 16px;
    --troika-spacing-300: 24px;
    --troika-spacing-400: 32px;
    --troika-spacing-500: 40px;
    --troika-spacing-600: 48px;
    --troika-spacing-700: 56px;
    --troika-spacing-800: 64px;
    --troika-spacing-900: 72px;
    --troika-spacing-1600: 128px;

    /* FINN base colors */
    --troika-primary-blue: #0063fb;
    --troika-secondary-blue: #06befb;
    --troika-focused: #66afe9;
    --troika-toothpaste: #b6f0ff;
    --troika-licorice: #474445;
    --troika-stone: #767676;
    --troika-sardine: #c3ccd9; /*previous: #DFE4E8 */
    --troika-ice: #f1f9ff;
    --troika-milk: #fff;
    --troika-marble: #f6f8fb;
    /* --troika-avocado: #277457;  removed */
    --troika-lime: #007200;
    --troika-pea: #2ee69f;
    --troika-mint: #ccffec;
    --troika-cherry: #d9270a;
    --troika-watermelon: #ff5844;
    --troika-salmon: #ffefef;
    --troika-banana: #fff5cb;

    /* @TODO revisit color palettes after input from Adi and Sanjin */
    /* Color palettes, used for interactivity like using a different color shade when a button is hovered */
    /* Generated using https://material.io/design/color/the-color-system.html#tools-for-picking-colors */
    /*--troika-primary-blue-100: #b9e1ff;
    --troika-primary-blue-200: #88cfff;
    --troika-primary-blue-300: #47bcff;
    --troika-primary-blue-400: #00abff;
    --troika-primary-blue-500: #009bff;
    --troika-primary-blue-600: #008cff;
    --troika-primary-blue-700: #0078ff;*/
    /* --troika-primary-blue-800: var(--troika-primary-blue); */
    /*--troika-primary-blue-800: #0063fb;
    --troika-primary-blue-900: #223fdc;

    --troika-secondary-blue-100: #b2e8fd;
    --troika-secondary-blue-200: #7fd9fc;
    --troika-secondary-blue-300: #48cafa;*/
    /* --troika-secondary-blue-400: var(--troika-secondary-blue); */
    /*--troika-secondary-blue-400: #06befb;
    --troika-secondary-blue-500: #00b2fb;
    --troika-secondary-blue-600: #00a3ec;
    --troika-secondary-blue-700: #0090d8;
    --troika-secondary-blue-800: #007fc5;
    --troika-secondary-blue-900: #005fa3;*/

    /* --troika-toothpaste-100: var(--troika-toothpaste); */
    /*--troika-toothpaste-100: #b6f0ff;
    --troika-toothpaste-200: #87e7fe;
    --troika-toothpaste-300: #59ddfb;
    --troika-toothpaste-400: #3cd4f6;
    --troika-toothpaste-500: #31ccf2;
    --troika-toothpaste-600: #2bbcde;
    --troika-toothpaste-700: #22a6c2;
    --troika-toothpaste-800: #1b92a8;
    --troika-toothpaste-900: #0d6f7b;

    --troika-lime-100: #d9f0c5;
    --troika-lime-200: #bfe7a0;
    --troika-lime-300: #a4dd78;
    --troika-lime-400: #8ed558;
    --troika-lime-500: #79cd36;
    --troika-lime-600: #6abd2e;
    --troika-lime-700: #54a824;
    --troika-lime-800: #3e9419;*/
    /* --troika-lime-900: var(--troika-lime); */
    /*--troika-lime-900: #007200;*/

    /* Combobox dropdown for troika-{element,react,vue}-combobox */
    --troika-combobox-list-border-radius: 0 0 var(--troika-spacing-100)
        var(--troika-spacing-100);
    --troika-combobox-list-padding: 0 0 var(--troika-spacing-100);
    --troika-combobox-list-box-shadow: 0px var(--troika-spacing-50)
        var(--troika-spacing-200) 0px rgba(71, 68, 69, 0.16);
    --troika-combobox-list-background-color: var(--troika-milk);

    /* TODO: item-color when we decide on a naming scheme for colors. This should be the default, so no need to specify it */
    --troika-combobox-list-item-text-color: var(--troika-licorice);
    --troika-combobox-list-item-padding: var(--troika-spacing-100);
    --troika-combobox-list-item-background-color-selected-hover: var(
        --troika-marble
    );

    --troika-combobox-list-heading-text-color: var(--troika-stone);
    --troika-combobox-list-heading-padding: var(--troika-spacing-100)
        var(--troika-spacing-100) var(--troika-spacing-50);
}
