@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";

/*


        Very low level element styling, no classes allowed, keep this as short as possible, most things should be opt in.


*/

html {
    font-size: 100%;
}
body {
    background-color: var(--troika-milk);
    /* Always stretch body to fill the width, this makes the behaviour of the scrollbar a little nicer in some cases */
    min-height: 100%;
    font-family: Finntype, Arial, Helvetica, sans-serif;
    font-size: $base-size--normal;
    line-height: $base-lineheight--normal;
    color: var(--troika-licorice);
}

body {
    /* Always show Scrollbar to prevent content jumping in certain situations (dynamic loading etc) */
    overflow-y: scroll;
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
}

h1,
h2,
h3,
h4,
h5,
ul,
ol,
dl,
p,
blockquote {
    margin: 0 0 8px 0;
}

h1,
h2,
h3,
h4,
h5,
img {
    padding-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 300; /* firefox translates normal to 400 other browsers 300 */
}

h1 {
    font-size: $t1-size--normal;
    line-height: $t1-lineheight--normal;
    font-weight: bold;
}

h2 {
    font-size: $t2-size--normal;
    line-height: $t2-lineheight--normal;
    font-weight: bold;
}

h3 {
    font-size: $t3-size--normal;
    line-height: $t3-lineheight--normal;
    font-weight: bold;
}

h4 {
    font-size: $t4-size--normal;
    line-height: $t4-lineheight--normal;
    font-weight: bold;
}

h5 {
    font-size: $t5-size--normal;
    line-height: $t5-lineheight--normal;
    font-weight: bold;
}

dt,
dd {
    margin: 0 16px;
}

table {
    font-size: inherit;
}

input {
    font-family: Finntype, Arial, Helvetica, sans-serif;
}
a {
    @include link;
    /* Only hide default focus outline styling when the keyboard isn't used to set focus */
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        outline-color: currentColor;
    }
}

/* Used to be part of normalize.css */
[hidden] {
    display: none !important;
}

/* tabindex=-1 are often used on skiplink targets, this ensures the outline is only visible when it should */
[tabindex="-1"]:focus:not(:focus-visible) {
    outline: none;
}

/*
 * normalize sets these to 'bolder', which doesn't work with our font
 */
b,
strong {
    font-weight: 700;
}
