/*

        Probably touch

        turn off focus for touch devices (Relies on a script that sets .probably-touch class on body)
        Should probably rather be defaulted to touch/mobile and set by prefixing with .probably-not-touch

        Possible future solve: https://caniuse.com/#feat=css-media-interaction

*/
.probably-touch:focus,
.probably-touch :focus {
    outline: none;
}
